import { Link } from "react-router-dom";
import "./PP-TOU.css";

const PrivacyPolicy = () => {
  return (
    <div className="pp-tou">
      <h2>
        <u>Fit for Everybody Privacy Policy</u>
      </h2>
      <p>Effective Date: November 14, 2020</p>
      <h3>1. Introduction</h3>
      <p>
        We at Fit for Everybody know you care about how your personally
        identifiable information is used and shared, and we take your privacy
        seriously. Please read the following to learn more about our Privacy
        Policy (the “Policy”). By using or accessing the Services in any manner,
        you acknowledge that you accept the practices and policies outlined in
        this Policy, and you hereby consent that we will collect, use, and share
        your information in the following ways.
      </p>
      <p>
        Please note that your use of Fit for Everybody’s services is at all
        times subject to the <Link to="/terms-of-use">Terms of Service</Link>.
        Any capitalized terms that are not defined in this Policy are defined in
        our <Link to="/terms-of-use">Terms of Service </Link>.
      </p>
      <h3>2. Information Collected</h3>
      <h4>a. Voluntarily disclosed information</h4>
      <p>
        When you use the Services, you will have the opportunity to provide us
        with some information directly. For example, if you choose to
        participate in our body measurement survey, we ask that you provide
        personal body measurements along with other information such as your
        name, demographic information, email address, and the country and state
        in which you currently reside.In addition, you may submit photos to
        ensure that our information encompasses all body shapes and takes in
        account what real women’s bodies look like. See Section 4 for more
        information about how we store this information and Section 3 for the
        kinds of information we may share with our customers. Measurement data
        that you choose to submit will be de-identified and aggregated with
        measurement data provided by other users so that we can produce
        analytics that will create a better sizing regime.
      </p>
      <h4>b. Automatically collected information</h4>
      <p>
        Whenever you interact with our Services, we automatically receive and
        record information on our server logs from your browser or device, which
        may include your IP address, geolocation data, device identification,
        “cookie” information, the type of browser and/or device you’re using to
        access our Services, and the page or feature you requested. “Cookies”
        are identifiers we transfer to your browser or device that allow us to
        recognize your browser or device and tell us how and when pages and
        features in our Services are visited and by how many people. You maybe
        able to change the preferences on your browser or device to prevent or
        limit your device’s acceptance of cookies, but this may prevent you from
        taking advantage of some of our features. We may receive a confirmation
        when you open an email from us. This confirmation helps us make our
        communications with you more interesting and improve our services.
      </p>
      <p>
        We may use this data to customize content for you that we think you
        might like, based on your usage patterns. We may also use it to improve
        the Services – for example, this data can tell us how often users use a
        particular feature of the Services, and we can use that knowledge to
        make the Services interesting to as many users as possible.
      </p>
      <h3>3. Disclosure of Information</h3>
      <p>
        We employ other companies and people to perform tasks on our behalf and
        need to share your information with them to provide products or services
        to you. For example, we use Qualtrics to collect your information
        through their survey tool. Additionally, our website is hosted on the
        Squarespace platform. Unless we tell you differently, our agents do not
        have any right to use the personally identifiable information we share
        with them beyond what is necessary to assist us.
      </p>
      <p>
        We may de-identify your personally identifiable information so that you
        are not identified as an individual, and provide that information to our
        partners. For example, with the measurement data we collect, we are
        developing an algorithm to inform retailers how to improve their sizing
        regimes. We may also provide aggregate usage information to our partners
        (or allow partners to collect that information from you), who may use
        such information to understand how often and in what ways people use our
        Services, so that they, too, can provide you with an optimal online
        experience. For example, Squarespace will have access to information
        demonstrating how our website users interact with our website in an
        anonymous, aggregated, or pseudonymized form.{" "}
        <b>
          However, we never disclose aggregate usage or de-identified
          information to a partner (or allow a partner to collect such
          information) in a manner that would identify you as an individual
          person.
        </b>
      </p>
      <p>
        We may choose to buy or sell assets, and may share and/or transfer user
        information in connection with the evaluation of and entry into such
        transactions. Also, if we (or our assets) are acquired, or if we go out
        of business, enter bankruptcy, or go through some other change of
        control, personally identifiable information could be one of the assets
        transferred to or acquired by a third party.
      </p>
      <p>
        We reserve the right to access, read, preserve, and disclose any
        information that we believe is necessary to comply with law or court
        order; enforce or apply our Terms of Service other agreements; or
        protect the rights, property, or safety of Fit for Everybody, our
        employees, our users, or others.
      </p>
      <h3>4. Security</h3>
      <p>
        If you fill out our body measurement survey, we store your email
        address, and photos. We endeavor to protect the privacy of your account
        and other personally identifiable information we hold in our records,
        but unfortunately, we cannot guarantee complete security. Unauthorized
        entry or use, hardware or software failure, and other factors, may
        compromise the security of user information at any time.
      </p>
      <h3>5. Modification and Deletion of Information</h3>
      <p>
        If you request, we will remove your email and all other personally
        identifiable information that you have provided from the Services.
        Please understand, however, that it may be impossible to delete this
        information completely, due to backups and records of deletions. In
        addition, please understand that, if you request removal of your
        information, you will be unable to utilize associated features of the
        Services. You may not request the removal of de-identified, anonymous,
        or aggregate data from our databases.
      </p>
      <h3>6. Age of Users</h3>
      <p>
        People under the age of 18 are not permitted to use, access or register
        for the Services in any way. We do not knowingly collect or solicit
        information from anyone under the age of 18. If we learn that we have
        collected personally identifiable information from a child under the age
        of 18, we will delete that information as quickly as possible.
      </p>
      <h3>7. Changes to the Policy</h3>
      <p>
        From time to time, Fit for Everybody may change its Privacy Policy. If
        we make any changes regarding disclosure of Personally Identifiable
        Information to third parties, we will attempt to notify you prior to the
        date of modified policy is scheduled to take effect. We will post notice
        of the new Policy from the privacy link on our Website. With respect to
        Users, your use of the Website following any such change constitutes
        your agreement to follow and be bound by the Privacy Policy, as changed.
      </p>
      <h3>8. Contact Us</h3>
      <p>
        If you have any questions about the Website, please do not hesitate to
        contact us at{" "}
        <a href="mailto:info@fitforeverybody.com">info@fitforeverybody.com</a>
      </p>
    </div>
  );
};

export default PrivacyPolicy;
