import "./Switch.css";

const Switch = ({ rounded = true, isToggled, onToggle }) => {
  let cn = "slider ";
  if (rounded) cn += "rounded";

  return (
    <label className="switch">
      <input type="checkbox" checked={isToggled} onChange={onToggle} />
      <span className={cn} />
    </label>
  );
};

export default Switch;
