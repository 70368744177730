import "./Newsletter.css";
import { useState, useEffect } from "react";
import MailchimpSubscribe from "react-mailchimp-subscribe";
import Button from "../components/Button";

const CustomForm = ({ status, message, onValidated }) => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");

  const [errorMessage, setErrorMessage] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    const re =
      //  /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (!firstName) setErrorMessage("First Name is required");
    else if (!lastName) setErrorMessage("Last Name is required");
    else if (!email) setErrorMessage("email is required");
    else if (!re.test(email))
      setErrorMessage("Please provide a valid email address");
    else {
      onValidated({
        EMAIL: email,
        MERGE1: firstName,
        MERGE2: lastName,
      });
    }
  };

  const clearFields = () => {
    setFirstName("");
    setLastName("");
    setEmail("");
    setErrorMessage("");
  };

  useEffect(() => {
    if (status === "success") clearFields();
    if (status === "error") setErrorMessage(message);
  }, [status, message]);

  return (
    <form
      onSubmit={(e) => handleSubmit(e)}
      style={{ width: "100%", height: "100%" }}
    >
      {errorMessage && (
        <div className="newsletter-error-message-container">
          <p className="newsletter-error-message">{errorMessage}</p>
        </div>
      )}
      <div className="input-container">
        {status === "success" ? (
          <h3 className="success-message">
            Thank you for subscribing to our newsletter!
          </h3>
        ) : (
          <div className="input-inner-container">
            <input
              id="newsletter-input"
              variant="outlined"
              placeholder="First Name"
              value={firstName}
              onChange={(event) => setFirstName(event.target.value)}
            />
            <input
              id="newsletter-input"
              variant="outlined"
              placeholder="Last Name"
              value={lastName}
              onChange={(event) => setLastName(event.target.value)}
            />
            <input
              id="newsletter-input"
              variant="outlined"
              placeholder="Email Address"
              value={email}
              onChange={(event) => setEmail(event.target.value)}
            />
            <Button
              className="signup-btn"
              type="submit"
              title="Sign Up"
              loading={status === "sending"}
            />
          </div>
        )}
      </div>
    </form>
  );
};

const Newsletter = () => {
  const postUrl = `https://fitforeverybody.us7.list-manage.com/subscribe/post?u=${process.env.REACT_APP_MAILCHIMP_U}&id=${process.env.REACT_APP_MAILCHIMP_ID}`;
  return (
    <div className="newsletter-container">
      <h1>Newsletter Sign-up</h1>
      <p>Sign up with your email address to receive news and updates.</p>
      <MailchimpSubscribe
        url={postUrl}
        render={({ subscribe, status, message }) => (
          <CustomForm
            status={status}
            message={message}
            onValidated={(formData) => subscribe(formData)}
          />
        )}
      />
    </div>
  );
};

export default Newsletter;
