import React, { useState, useContext } from "react";
import { NavLink, Link } from "react-router-dom";
import { MenuItems } from "./MenuItems";
import { AccountContext } from "../Accounts";
import "./Navbar.css";

import { AiOutlineMenu, AiOutlineClose } from "react-icons/ai";

const Navbar = () => {
  const [clicked, setClicked] = useState(false);
  const { loggedIn, logout } = useContext(AccountContext);

  return (
    <nav className="NavbarItems">
      <Link to="/" className="navbar-logo" onClick={() => setClicked(false)}>
        <img
          src="/fit-for-everybody-logo.png"
          alt="Fit_for_Everybody_Logo"
          className="logo-image"
        />
      </Link>

      <div
        className={clicked ? "menu-icon close" : "menu-icon"}
        onClick={() => setClicked(!clicked)}
      >
        {clicked ? <AiOutlineClose /> : <AiOutlineMenu />}
      </div>
      <ul className={clicked ? "nav-menu active" : "nav-menu"}>
        {MenuItems.map((item, index) => {
          return item.title !== "Log Out" ? (
            <li key={index}>
              <NavLink
                to={item.url}
                onClick={() => setClicked(false)}
                className={item.cName}
                activeClassName="active"
                exact
              >
                {item.title}
              </NavLink>
            </li>
          ) : loggedIn ? (
            <li key={index}>
              <Link
                to={item.url}
                onClick={() => {
                  logout();
                  setClicked(false);
                }}
                className={item.cName}
                activeClassName="active"
              >
                {item.title}
              </Link>
            </li>
          ) : null;
        })}
      </ul>
      {loggedIn ? (
        <Link to="/" onClick={logout} className="logout-button">
          Log Out
        </Link>
      ) : null}
    </nav>
  );
};

export default Navbar;
