import { useState, useContext } from "react";
import { CognitoUser } from "amazon-cognito-identity-js";
import Pool from "../components/UserPool";
import { AccountContext } from "../components/Accounts";
import "./ForgotPassword.css";
import LoadingButton from "@mui/lab/LoadingButton";
import TextField from "@mui/material/TextField";
import ErrorMessage from "../components/ErrorMessage";
import { useHistory } from "react-router-dom";

import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

const ForgotPassword = () => {
  const [stage, setStage] = useState(1); // 1 == email stage; 2 == code stage
  const [email, setEmail] = useState("");
  const [code, setCode] = useState("");
  const [password, setPassword] = useState("");
  const [passwordConfirmation, setPasswordConfirmation] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);

  const [showPassword, setShowPassword] = useState(false);

  // for navigating to different page
  const history = useHistory();

  const { authenticate } = useContext(AccountContext);

  const getUser = () => {
    return new CognitoUser({
      Username: email.toLowerCase(),
      Pool,
    });
  };

  const sendCode = (event) => {
    event.preventDefault();

    if (!email) return setErrorMessage("Email is required.");

    setLoading(true);

    getUser().forgotPassword({
      onSuccess: (data) => {
        console.log("onSuccess:", data);
        setErrorMessage("");
      },
      onFailure: (err) => {
        setErrorMessage(err.message);
        setLoading(false);
      },
      inputVerificationCode: (data) => {
        console.log("Input code:", data);
        setStage(2);
        setLoading(false);
      },
    });
  };

  const resetPassword = (event) => {
    event.preventDefault();

    if (password !== passwordConfirmation)
      return setErrorMessage("Passwords do not match.");
    else if (!code) return setErrorMessage("Code is required.");
    else if (!password || !passwordConfirmation)
      return setErrorMessage("Passwords are required.");

    setLoading(true);

    getUser().confirmPassword(code, password, {
      onSuccess: (data) => {
        authenticate(email, password)
          .then((data) => {
            history.push("/shopper-landing-page");
          })
          .catch((err) => {
            setErrorMessage(err.message);
            setLoading(false);
          });
      },
      onFailure: (err) => {
        setErrorMessage(err.message);
        setLoading(false);
      },
    });
  };

  return (
    <div className="forgot-password-container">
      {stage === 1 && (
        <div className="form">
          <h1 className="welcome-message">Reset Password</h1>
          <TextField
            className="login-input"
            label="Email"
            variant="standard"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <ErrorMessage message={errorMessage} className="error-message" />
          <LoadingButton
            loading={loading}
            variant="outlined"
            className="log-in-btn"
            onClick={sendCode}
            style={{ marginBottom: 30 }}
          >
            Send Verification Code
          </LoadingButton>
        </div>
      )}
      {stage === 2 && (
        <div className="form">
          <h1 className="welcome-message">Reset Password</h1>
          <TextField
            className="login-input"
            label="Code"
            variant="standard"
            value={code}
            onChange={(e) => setCode(e.target.value)}
            type="number"
          />
          <TextField
            className="login-input"
            label="New Password"
            variant="standard"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            type={showPassword ? "text" : "password"}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => setShowPassword(!showPassword)}
                    onMouseDown={() => setShowPassword(!showPassword)}
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />

          <TextField
            className="login-input"
            label="Password Confirmation"
            variant="standard"
            value={passwordConfirmation}
            onChange={(e) => setPasswordConfirmation(e.target.value)}
            type={showPassword ? "text" : "password"}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => setShowPassword(!showPassword)}
                    onMouseDown={() => setShowPassword(!showPassword)}
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <ErrorMessage message={errorMessage} />
          <LoadingButton
            loading={loading}
            variant="outlined"
            className="log-in-btn"
            onClick={resetPassword}
            style={{ marginBottom: 30 }}
          >
            Change Password
          </LoadingButton>
        </div>
      )}
    </div>
  );
};

export default ForgotPassword;
