import "./Blog.css";
import { useState, useEffect } from "react";
import BlogPost from "../components/BlogPost";
import ReactLoading from "react-loading";

const Blog = () => {
  const [blogs, setBlogs] = useState([]);

  const removeTags = (str) => {
    if (str === null || str === "") return false;
    else str = str.toString();
    return str.replace(/(<([^>]+)>)/gi, "");
  };

  useEffect(() => {
    fetch("https://fitforeverybodyserver.com/s3-excel", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ name: "blog-page/blog-directory" }),
    })
      .then((response) => response.json())
      .then((data) => {
        for (const post of data["excel"]) {
          // this can be sped up by only converting the first n characters that will be previewed
          post.push(removeTags(post[2]));
        }
        setBlogs(data["excel"].reverse());
      });
  }, []);

  return (
    <div className="blog-container">
      <h1 className="title">Blog</h1>
      {blogs.length ? (
        blogs.map((blog, i) => (
          <BlogPost
            key={i}
            id={blog[0]}
            title={blog[1]}
            body_string={blog[3]}
          />
        ))
      ) : (
        <div className="spinner-container">
          <ReactLoading type="spinningBubbles" color="pink" width={100} />
        </div>
      )}
    </div>
  );
};

export default Blog;
