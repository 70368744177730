import React from "react";
import "./Instruction-Introduction.css";
import Button from "@mui/material/Button";

class Introduction extends React.Component {
  continue = (e) => {
    e.preventDefault();
    this.props.nextStep();
  };

  render() {
    let minutes = "";
    switch (this.props.type) {
      case "10":
        minutes = "10";
        break;
      case "20":
        minutes = "20";
        break;
      case "all":
        minutes = "45";
        break;
      default:
        minutes = "30-40";
    }

    return (
      <div className="instruction-introduction-container">
        <h2 className="title">
          {/* capitalizing the first letter of the word e.g. self --> Self */}
          {this.props.type.replace(/\w\S*/g, function (txt) {
            return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
          })}{" "}
          Measurements
        </h2>
        <h3 className="subtitle">{minutes} minutes</h3>
        <p className="body">
          By starting this survey, you agree to our{" "}
          <a
            href="https://fitforeverybody.com/terms-of-use"
            target="_blank"
            rel="noreferrer"
          >
            Terms of Use
          </a>{" "}
          and acknowledge that you have read our{" "}
          <a
            href="https://fitforeverybody.com/privacy-policy"
            target="_blank"
            rel="noreferrer"
          >
            Privacy Policy
          </a>{" "}
          to learn how we handle your data.
        </p>
        <div className="btn-container">
          <Button onClick={this.continue} className="btn" variant="outlined">
            Start the Survey
          </Button>
        </div>
      </div>
    );
  }
}

export default Introduction;
