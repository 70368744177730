import "./BodyImage.css";
import "./Measurement.css";
import React, { useState, useContext } from "react";
import Button from "@mui/material/Button";
import LoadingButton from "@mui/lab/LoadingButton";
import FileUpload from "../components/FileUpload";
import { AccountContext } from "../components/Accounts";

const BodyImage = ({ prevStep, nextStep }) => {
  const [frontBodyImage, setFrontBodyImage] = useState();
  const [backBodyImage, setBackBodyImage] = useState();
  const [sideBodyImage, setSideBodyImage] = useState();

  const [loading, setLoading] = useState(false);

  const { getSession } = useContext(AccountContext);

  const postImage = async () => {
    const formData = new FormData();
    formData.append("frontBodyImage", frontBodyImage);
    formData.append("backBodyImage", backBodyImage);
    formData.append("sideBodyImage", sideBodyImage);
    let data = await getSession();
    formData.append("userID", data["sub"]);

    return fetch("https://user.fitforeverybody.com/api/vi/bodyImage/add", {
      method: "POST",
      body: formData,
    }).then((response) => response.json());
  };
  const submit = async (event) => {
    event.preventDefault();
    setLoading(true);
    await postImage();
    setLoading(false);
    nextStep();
  };

  return (
    <div class="measurement-container">
      <h3 class="title">Body Image</h3>
      <div id="body-photos-outer-container">
        <div id="body-photos-left-container">
          <p id="body-photos-description">
            Photo Upload (Optional). If you are comfortable, please upload a
            front, back, and side photo of yourself. Please do not include your
            face (neck down). Please do include down to your feet. Please take
            the photo against a solid background (a wall or a door work great).
          </p>
          <img
            src="/frontbackside.jpg"
            alt="frontbackside"
            id="ex-body-image"
          />
        </div>
        <div id="body-photos-right-container">
          <FileUpload
            name="bodyFrontImage"
            accept="image/*"
            setFile={setFrontBodyImage}
            file={frontBodyImage}
            title="Front body"
          />
          <FileUpload
            name="bodySideImage"
            accept="image/*"
            setFile={setSideBodyImage}
            file={sideBodyImage}
            title="Side body"
          />
          <FileUpload
            name="bodyBackImage"
            accept="image/*"
            setFile={setBackBodyImage}
            file={backBodyImage}
            title="Back body"
          />
        </div>
      </div>
      <div className="btn-container">
        <Button className="btn" onClick={prevStep} variant="outlined">
          Back
        </Button>
        <LoadingButton
          className="btn"
          onClick={submit}
          variant="outlined"
          loading={loading}
        >
          Continue
        </LoadingButton>
      </div>
    </div>
  );
};

export default BodyImage;
