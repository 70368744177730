import ReactLoading from "react-loading";

const Button = ({ onClick, loading, title, style }) => {
  return (
    <button
      style={{ ...buttonStyles.button, ...style }}
      onClick={onClick}
      disabled={loading}
    >
      {loading ? <ReactLoading type="bubbles" color="pink" /> : title}
    </button>
  );
};

const buttonStyles = {
  button: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
};

export default Button;
