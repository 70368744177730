import "./FitTips.css";
import FitTip from "../components/FitTip";

const FitTips = () => {
  return (
    <div className="fit-tips-container">
      <span className="fit-tips-title">
        How can I tell if my clothing fits me?
      </span>
      <p>Great question!</p>
      <p>
        Fit is a combination of personal taste, preferences, garment style, body
        measurements, and garment measurements.
      </p>
      <p>You may prefer clothing tighter or looser, longer or shorter - </p>
      <p>
        Some garments are aesthetically designed to be long, oversized, cropped,
        or ultra-fitted.
      </p>
      <p>
        Below, we share a few “fit tips” on garment measurements as they relate
        to your body.
      </p>
      <FitTip
        isImgLeft={true}
        imgUrl="./fit-tip-1.jpg"
        caption="If this is the case for you, consider sizing up."
        number={1}
        description="Your clothing may be too tight if…"
        list={[
          "You see horizontal wrinkles",
          "You feel the garment squeezing into your body",
          "Buttons, clasps, or zippers gape, and you can see your skin",
          "The fabric rides up or down to a narrower point on your body",
          "Your movement is restricted ",
        ]}
      />
      <FitTip
        isImgLeft={false}
        imgUrl="./fit-tip-2.png"
        caption="If this is the case for you, consider sizing down."
        number={2}
        description="Your clothing may be too loose if…"
        list={[
          "You see vertical wrinkles forming as the garment sits on your body ",
          "The garment extends beyond your body or droops, which may be seen with shoulder widths in shirts and blazers",
        ]}
      />
      <FitTip
        isImgLeft={true}
        imgUrl="./fit-tip-3.jpg"
        caption="If this is the case for you, order a size that can fit your largest measurement comfortably, you can always take in extra fabric with accessories or tailoring."
        number={3}
        description="Your clothing may be the wrong proportion if…"
        list={["The garment feels tight in some areas but loose in others"]}
      />
      <FitTip
        isImgLeft={false}
        imgUrl="./fit-tip-4.png"
        caption="If this is the case for you, consider tailoring to shorten or try a petite size if available."
        number={4}
        description="Your clothing may be too long if… "
        list={["Pants drag on the floor", "Sleeves cover your hands"]}
      />
      <FitTip
        isImgLeft={true}
        imgUrl="./fit-tip-5.png"
        caption="If this is the case for you, check inside the hems to see if there is extra fabric that could be let out by a tailor or try tall sizes if available."
        number={5}
        description="Your clothing may be too short if…"
        list={[
          "More of your leg shows than you prefer for pants/skirts",
          "A sleeve shows more of your arm at the wrist than you prefer",
          "For shirts, your belly shows when you raise your hands above your head",
        ]}
      />
      <FitTip
        isImgLeft={true}
        imgUrl=""
        caption=""
        number={6}
        description="Your clothing fits correctly if… "
        list={[
          "The garment skims over your body without wrinkling",
          "You can comfortably move (raise your hands, sit, stand, breathe) ",
          "The length of the garment covers your body to your preferred length",
        ]}
      />
    </div>
  );
};

export default FitTips;
