import "./FitTip.css";

const FitTip = ({ isImgLeft, imgUrl, caption, number, description, list }) => {
  return (
    <div className="fit-tip-container">
      {window.innerWidth > 960 && isImgLeft && imgUrl ? (
        <div className="fit-tip-image">
          <img src={imgUrl} alt={imgUrl} />
          <p>{caption}</p>
        </div>
      ) : null}

      {/* width style for the last fit tip without image */}
      <div className={imgUrl ? "fit-tip-list" : "fit-tip-list without-image"}>
        <p>
          Fit Tip #{number}: <span>{description}</span>
        </p>

        <ol>
          {list.map((tip) => (
            <li>{tip}</li>
          ))}
        </ol>
      </div>

      {(window.innerWidth <= 960 || !isImgLeft) && imgUrl ? (
        <div className="fit-tip-image">
          <img src={imgUrl} alt={imgUrl} />
          <p>{caption}</p>
        </div>
      ) : null}
    </div>
  );
};

export default FitTip;
