import "./ShopperDesigner.css";

const About = () => {
  return (
    <div className="shopper-designer-container">
      <h1>Our Mission: Fit for Everybody</h1>
      <p>
        Here at Fit for Everybody, we understand that fit begins at the design
        stage and believe that every woman has the right and ability to feel
        confident and beautiful in her own skin and the clothing she puts on her
        body.
      </p>
      <p>Our integrated approach to sizing and fit has two primary elements:</p>
      <p>
        For designers: Improve the accuracy and consistency of your sampling
        process through our easy to use digital tech pack platform.
      </p>
      <p>
        For shoppers: Build your size profile to shop better now and improve
        size and fit in the future.
      </p>
    </div>
  );
};

export default About;
