import "./DesignerQandA.css"

const DesignerQandA = () => {
  return (
     <section id="designer-qa-container">
       <p>
         <h2>What is Fit for Everybody?</h2>
         Fit for Everybody is a web-based tech pack platform aimed to improve fit and sizing of womenswear from the design stage.
       </p>
       <p>
         <h2>How does Fit for Everybody do this?</h2>
         Fit for Everybody is taking a holistic approach to sizing.
         <br />
         <br />
         Our founder, Laura, started by measuring 200 women in person with her personal tape measure, finding that many of these women had very little physically in common with “standard” sizes. Once COVID started, Laura put how-to videos online to teach individuals how to take their measurements and store a size profile. Our long-term goal is to use these measurements anonymously to include more women in the process of developing sizing.         <br />
         <br />
         In the meantime, we found through our research that <strong>sizing is functionally a communication problem.</strong> The sizing process begins with a first sample designed by a designer and made by a factory that is then scaled up and down for different sizes in a process called grading. If a “correct” sample is not achieved, and a “good enough” sample is approved, then any scaled sizing will become worse and worse (like a domino effect), and different factories used by the same brand may size differently if not informed otherwise.
         <br />
         <br />
         Fit for Everybody’s platform for designers and factories streamlines sample development and production through easy-to-use, design-centric tech pack templates and features that improve communication.          <br />
         <br />
         Through software and consulting, we help brands and designers communicate effectively with factories to achieve a correct sample quickly, hone consistent sizing and understand their fit, and quality control their production.
       </p>
       <p>
         <h2>What are specific features of Fit for Everybody’s platform?</h2>
         Fit for Everybody’s platform turns designers’ and factories’ <strong>line sheets into mailboxes</strong> so you can work directly with your factory through the platform and have all your specs, images, and comments in one place, by style throughout the development process.
         <br />
         <br />
         <strong>Chat with translation</strong> enables Q and A between designers and the factory floor during development, as well as the ability to upload photos and spec checks of work in progress and before shipping samples for fitting.
         <br />
         <br />
         Our distilled and minimalist, paint-by-number tech pack format helps designers hone their design instructions to a <strong>1-pager</strong> that can be easily used on the factory floor and minimize miscommunication. The history of the garment is kept in one place to minimize administrative busy work. The platform can be used by the designer without a factory on the platform, but works best when everyone is working together!
         <br />
         <br />
         Fit for Everybody provides <strong>point of measurement diagrams</strong> by garment category for use by designers and factories, so measurements are well defined and shared. We can set up custom point of measurement diagrams for use by designers or factories when we create your account upon request, so both sides know to take measurements from the same point.
         <br />
         <br />
         Once a sample is followed through development, we provide <strong>quality control templates</strong> for recording your production spec by garment, for a clear baseline that can be compared to sales data and honed through an iterative process to ensure your company has one size standard across factories and that the best-selling sizing is used throughout.
       </p>
       <p>
         <h2>What are clients saying?</h2>
         <figure>
           <figcaption>Designer in Established Brand:</figcaption>
           <blockquote>
             “I love this layout… <strong>so visible and organized! I tried out the chat feature, love it!</strong> I added in my own measurement. <strong>Everything was working really really smooth!</strong>”
           </blockquote>
         </figure>
         <br />
         <figure>
           <figcaption>Non-Industry Designer:</figcaption>
           <blockquote>
             “Like I said, really great, <strong>not overwhelming</strong> but has the <strong>essentials to stay organized.</strong>”
           </blockquote>
         </figure>
         <br />
         <figure>
           <figcaption>Designer in Established Brand to Designer with Own Brand (Introduction):</figcaption>
           <blockquote>
             [FFE] is "a program for designers and factories to have specs, attachments, fit photos, and even a chat feature all in one spot. There is also more exciting stuff she is working on for the future like grading and ways to help reduce the amount of DUPs/protos ($$). It dawned on me that you guys might be super interested too! I feel like it’s great to have everything in one place, and am especially enjoying it with some of my smaller factories.”
           </blockquote>
         </figure>
         <br />
         <figure>
           <figcaption>Sustainability Consultant:</figcaption>
           <blockquote>
             [FFE] is "Instrumental not just to the voice of the factory but also in sustainability."
           </blockquote>
         </figure>
       </p>
       <p>
         <h2>Is Fit for Everybody sustainable?</h2>
         We aim to be, but acknowledge that sustainability is not a metric.
         <br />
         <br />
         We aim for mindful sampling, production, and consumption, through cutting waste in the sampling process caused by miscommunication, saving $$, time, and frustration.
         <br />
         <br />
         We <strong>reduce the number of rounds of sampling</strong> through improved communication and check points, so fewer samples are made and shipped compared to the industry standard of sending a static tech pack to a factory and receiving an incorrect sample four weeks later with no or fragmented mid-development communication.
         <br />
         <br />
         Our long-term goal is to hone production to more democratic sizing, so that supply more closely matches demand to reduce waste due to over and mis production due to poor fit, while <strong>increasing sell through and reducing returns.</strong>
       </p>
       <p>
         <h2>Is Fit for Everybody right for my brand or factory?</h2>
         Fit for Everybody works with small and mid-size designers and factories to improve communication during the sampling process. <strong>We replace analogue and Excel tech packs.</strong> If you have seen a basic template of a spec sheet, there is nothing on our platform that should surprise you, and we provide videos for the basic functions of the platform, and a running updated features list that is emailed to users upon addition to the platform.
         <br />
         <br />
         We can be used internally by designers, by factories, but work best when designers and factories work together.
       </p>
       <p>
         <h2>How to reach out:</h2>
         Email us at <a id="email-link" href="mailto:info@fitforeverybody.com">info@fitforeverybody.com</a>
         <br />
         <br />
         Follow us on social <strong>@_fit_for_everybody_</strong>
         <br />
         <br />
         Thank you!
       </p>
     </section>
  );
};

export default DesignerQandA;