import "./MeasurementDetail.css";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Switch from "../components/Switch";

const MeasurementDetail = () => {
  const { survey_type, id } = useParams();
  const [measurement, setMeasurement] = useState([]);
  const [showMetric, setShowMetric] = useState(true);

  const replaceAll = (string, old, target) => {
    // change all occurrences of character "old" to "target" in string "string"
    return string.split(old).join(target);
  };

  useEffect(() => {
    fetch("https://fitforeverybodyserver.com/get-measurement", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        survey_type: survey_type.toLowerCase(),
        id,
      }),
    })
      .then((response) => response.json())
      .then((data) => setMeasurement(data.data[0]));
  }, [id, survey_type]);

  return (
    <div className="measurement-detail-container">
      <h1>{survey_type} Measurements</h1>
      <h3>Date: {new Date(measurement.completed_on).toDateString()}</h3>
      <div className="switch-container">
        <span>inch</span>
        <Switch
          isToggled={showMetric}
          onToggle={() => setShowMetric(!showMetric)}
          color="pink"
        />
        <span>centimeter</span>
      </div>
      {Object.keys(measurement).map((key) =>
        key !== "id" && key !== "completed_on" && key !== "user_id" ? (
          <p className="measurement">
            <span className="body-part-key">{replaceAll(key, "_", " ")}</span> :{" "}
            <span className="body-part-value">
              {/* convert between inch and cm */}
              {measurement[key] * showMetric +
                ((measurement[key] * 1) / 2.54).toFixed(2) * !showMetric}
            </span>{" "}
            {showMetric ? "cm" : "inch"}
          </p>
        ) : null
      )}
    </div>
  );
};

export default MeasurementDetail;
