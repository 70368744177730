import "./DesignerCallToAction.css"

const DesignerCallToAction = () => {

  return (
     <p id="designer-cta-container">
       <div className="cta-section">
         <img src="./dollar_sign.png" alt="Icon of a dollar sign" />
         <p>Save up to 30% Sampling Cost</p>
       </div>
       <div className="cta-section">
         <img src="./stopwatch.png" alt="Icon of a stopwatch" />
         <p>Save up to 2 weeks Development</p>
       </div>
       <div className="cta-section">
         <img src="./message_icon.png" alt="Icon of messages" />
         <p>Improve Communication</p>
       </div>
     </p>
  );
};

export default DesignerCallToAction;