import { Link } from "react-router-dom";
import "./PP-TOU.css";

const TermsOfUse = () => {
  return (
    <div className="pp-tou">
      <h2>
        <u>Fit for Everybody Website Terms of Use</u>
      </h2>
      <p>Effective Date: November 14, 2020</p>
      <p>
        The following Terms of Use (the "<b>Terms</b>") constitute a binding
        agreement between you and [Fit for Everybody] ("<b>Fit for Everybody</b>
        ," “<b>we</b>,” “<b>our</b>” and “<b>us</b>”). These Terms set forth
        conditions regarding your access to and use of the Fit for Everybody
        website (the “<b>Website</b>”).
      </p>
      <p>
        By accessing or using the Website in any manner, including but not
        limited to visiting or browsing the Website or contributing content or
        other materials to the Website, you agree to be bound by these Terms.
      </p>
      <h3>1. Modification</h3>
      <p>
        Fit for Everybody reserves the right, at its sole discretion, to modify
        these Terms at any time and without prior notice. If we modify these
        Terms, we will either post a notification of the modification on the
        Website or otherwise provide you with notice of the change. The date of
        the last modification will also be posted at the beginning of these
        Terms. It is your responsibility to check from time to time for updates.
        By continuing to access or use the Website, you are indicating that you
        agree to be bound by any modified Terms.
      </p>
      <h3>2. Privacy Policy</h3>
      <p>
        Our <Link to="/privacy-policy">Privacy Policy</Link> discusses how we
        collect, process, and disclose personal information that you submit to
        Fit for Everybody through the Website. Please read that policy
        carefully. We also explain the license you grant to Fit for Everybody
        with respect to any submitted information in Section 6. below.
      </p>
      <h3>3. Eligibility</h3>
      <p>
        The Website is intended solely for persons who are at least 18 years
        old. By using the Website, you represent and warrant that you are at
        least 18 years old. If you are not 18 or older, you may not use the
        Website.
      </p>
      <h3>4. Acceptable Use</h3>
      <p>
        Fit for Everybody hereby grants you permission to access and use the
        Website provided such use is in compliance with these Terms, and you
        further specifically agree that youruse will adhere to the following
        restrictions and obligations:
      </p>
      <ol type="a">
        <li>You may only use the Website for personal use;</li>
        <li>
          You may only use the Website for lawful activity. It is your
          responsibility to comply withall applicable local, state, and federal
          laws and regulations;
        </li>
        <li>
          You may not interfere with or damage the Website, including, without
          limitation, throughthe use of viruses, bots, harmful code,
          denial-of-service attacks, backdoors, packet or IP address spoofing,
          forged routing, or any similar methods or technology;
        </li>
        <li>
          You may not copy, rip, or capture any content encountered on the
          Website. This includes bulk copying or "scraping" any portion of the
          Website content using a bot or other tool;
        </li>
        <li>
          You may not use the Website to upload, transmit, or promote any
          material that infringes or violates the intellectual property rights,
          privacy rights, or any other rights of anyone else (including Fit for
          Everybody);
        </li>
        <li>
          You may not decompile, reverse engineer, or otherwise attempt to
          obtain the source code or underlying ideas or information of or
          relating to the Website;
        </li>
      </ol>
      A violation of any of the foregoing is grounds for termination of your
      right to use or access the Website.
      <h3>5. Content</h3>
      <p>
        The materials displayed or performed or available on or through the
        Website, including, but not limited to, text, graphics, data, articles,
        photos, images, and illustrations (all of the foregoing except User
        Content, the “<b>Content</b>”), are protected by copyright and/or other
        intellectual property laws.{" "}
      </p>
      <p>
        You acknowledge that the Website and Content, including all associated
        intellectual property rights, are the exclusive property of Fit for
        Everybody.
      </p>
      <p>
        Conditioned upon your compliance with these Terms, Fit for Everybody
        grants you a limited, non-exclusive, non-transferable license, to (i)
        access, view, and use the Website solely for your personal use and (ii)
        access and view any Content to which you are permitted access. You have
        no right to sublicense the licensed rights granted in this section. You
        may not use, copy, adapt, modify, prepare derivative works based upon,
        distribute, license, sell, transfer, publicly display, transmit,
        broadcast or otherwise exploit the Website, except as expressly
        permitted in these Terms. No licenses or rights are granted to you by
        implication or otherwise under any intellectual property rights owned or
        controlled by Fit for Everybody or its licensors, except for the
        licenses and rights expressly granted in these Terms.
      </p>
      <h3>6. User Content</h3>
      <p>
        Fit for Everybody is building a service through which it aims to inform
        clothing retailers how to improve their sizing, improve the shopping
        experience for women, and reduce waste in the fashion cycle
        (collectively, the “<b>Services</b>”). In order to develop the Services,
        we may, at our sole discretion, permit you to upload, submit or transmit
        content, including but not limited to body measurements (“
        <b>User Content</b>”). By submitting any User Content on or through the
        Website, you grant to Fit for Everybody a worldwide, irrevocable,
        perpetual, non-exclusive, transferable, royalty-free license, with the
        right to sublicense, to use, view, copy, adapt, modify, and access such
        User Content, in any media, in order to operate or improve the Services.
        As described in our Privacy Policy, to the extent that your User Content
        includes personally identifiable information, we will not disclose any
        of that information to third parties. This means that to the extent we
        disclose body measurements to third parties, those body measurements
        will not be linked specifically to anyone’s personally identifiable
        information. In addition, to the extent that Fit for Everybody
        de-identifies and aggregates any User Content, you agree that such
        derived data is no longer User Content, and is thus owned by Fit for
        Everybody.
      </p>
      <h3>7. Third Party Content</h3>
      <p>
        By using the Website, Fit for Everybody may provide you with access to
        third party websites, information, and services, including but not
        limited to third party software, programs, systems, applications, or
        products. One such example is our third party survey platform. You
        hereby acknowledge that Fit for Everybody does not control such
        third-party websites and services, and cannot be held responsible for
        their content, operation, or use. Your use of those services is subject
        to their respective terms of service. Fit for Everybody does not give
        any representation, warranty, or endorsement, express or implied, with
        respect to the legality, accuracy, quality, or authenticity of content,
        information, or services provided by such third-party websites and
        services. Fit for Everybody disclaims any and all responsibility or
        liability for any harm resulting from your use of such third-party
        websites and services, and you hereby irrevocably waive any claim
        against Fit for Everybody with respect to the content or operation of
        any such third-party websites and services.
      </p>
      <h3>8. Feedback</h3>
      <p>
        We welcome and encourage you to provide feedback, comments and
        suggestions for improvements to the Website (“<b>Feedback</b>”). You
        agree that Fit for Everybody has the right, but not the obligation, to
        use such Feedback without any obligation to provide you credit, royalty
        payment, or ownership interest in the changes to the Website.
      </p>
      <h3>9. Termination</h3>
      <p>
        Fit for Everybody may immediately and without notice terminate these
        Terms and disable your access to the Website if Fit for Everybody
        determines, in its sole discretion, that (a) you have materially
        breached these Terms; (b) you have violated applicable laws, regulations
        or third party rights; or (c) Fit for Everybody believes, in good faith,
        that such action is needed to protect the safety or property of other
        users, Fit for Everybody, or third parties. Provisions that, by their
        nature, should survive termination of these Terms shall survive
        termination. By way of example, all of the following will survive
        termination: any limitations on our liability, any terms regarding
        ownership or intellectual property rights, and terms regarding disputes
        between us.
      </p>
      <h3>10. Disclaimer of Warranties</h3>
      <p>
        YOU HEREBY ACKNOWLEDGE THAT YOU ARE USING THE WEBSITE AT YOUR OWN RISK.
        THE WEBSITE AND CONTENT ARE PROVIDED "AS IS," AND FIT FOR EVERYBODY, ITS
        AFFILIATES AND ITS THIRD PARTY SERVICE PROVIDERS HEREBY DISCLAIM ANY AND
        ALL WARRANTIES, EXPRESS AND IMPLIED, INCLUDING BUT NOT LIMITED TO ANY
        WARRANTIES OF ACCURACY, RELIABILITY, MERCHANTABILITY, NON-INFRINGEMENT,
        FITNESS FOR A PARTICULAR PURPOSE, AND ANY OTHER WARRANTY, CONDITION,
        GUARANTEE OR REPRESENTATION, WHETHER ORAL, IN WRITING OR IN ELECTRONIC
        FORM. FIT FOR EVERYBODY, ITS AFFILIATES, AND ITS THIRD PARTY SERVICE
        PROVIDERS DO NOT REPRESENT OR WARRANT THAT ACCESS TO THE WEBSITE WILL BE
        UNINTERRUPTED OR THAT THERE WILL BE NO FAILURES, ERRORS OR OMISSIONS OR
        LOSS OF TRANSMITTED INFORMATION, OR THAT NO VIRUSES WILL BE TRANSMITTED
        THROUGH THE WEBSITE.
      </p>
      <h3>11. Limitation of Liability</h3>
      <p>
        TO THE FULLEST EXTENT ALLOWED BY APPLICABLE LAW, UNDER NO CIRCUMSTANCES
        AND UNDER NO LEGAL THEORY (INCLUDING, WITHOUT LIMITATION, TORT,
        CONTRACT, STRICT LIABILITY, OR OTHERWISE) SHALL FIT FOR EVERYBODY (OR
        ITS LICENSORS OR SUPPLIERS) BE LIABLE TO YOU OR TO ANY OTHER PERSON FOR
        (A) ANY INDIRECT, SPECIAL, INCIDENTAL, OR CONSEQUENTIAL DAMAGES OF ANY
        KIND, INCLUDING DAMAGES FOR LOST PROFITS, LOSS OF GOODWILL, WORK
        STOPPAGE, ACCURACY OF RESULTS, OR COMPUTER FAILURE OR MALFUNCTION, (B)
        ANY AMOUNT, IN THE AGGREGATE, IN EXCESS OF $20, OR (C) ANY MATTER BEYOND
        OUR REASONABLE CONTROL. SOME STATES DO NOT ALLOW THE EXCLUSION OR
        LIMITATION OF CERTAIN DAMAGES, SO THE ABOVE LIMITATION AND EXCLUSIONS
        MAY NOT APPLY TO YOU.
      </p>
      <h3>12. Notices</h3>
      <p>
        Any notices or other communications permitted or required hereunder,
        including those regarding modifications to these Terms, will be in
        writing and given by Fit for Everybody (a) via email (in each case to
        the address that you provide) or (b) by posting to the Website.
      </p>
      <h3>13. No Waiver</h3>
      <p>
        The failure of Fit for Everybody to enforce any right or provision of
        these Terms will not constitute a waiver of future enforcement of that
        right or provision.
      </p>
      <h3>14. Assignment</h3>
      <p>
        You may not assign or transfer these Terms, by operation of law or
        otherwise, without Fit for Everybody’s prior written consent. Any
        attempt by you to assign or transfer these Terms without such consent
        will be null and of no effect. Fit for Everybody may assign or transfer
        these Terms, at its sole discretion, without restriction. Subject to the
        foregoing, these Terms will bind and inure to the benefit of the
        parties, their successors and permitted assigns. Unless a person or
        entity is explicitly identified as a third party beneficiary to these
        Terms, these Terms do not and are not intended to confer any rights or
        remedies upon any person or entity other than the parties.
      </p>
      <h3>15. Severability</h3>
      <p>
        f for any reason an arbitrator or a court of competent jurisdiction
        finds any provision of these Terms invalid or unenforceable, that
        provision will be enforced to the maximum extent permissible and the
        other provisions of these Terms will remain in full force and effect.
      </p>
      <h3>16. Governing Law</h3>
      <p>
        These Terms (and any further rules, polices, or guidelines incorporated
        by reference) shall be governed and construed in accordance with the
        laws of the Commonwealth of Massachusetts. Any action based on, relating
        to, or alleging breach of these Terms must be brought in a state or
        federal court in Middlesex County, Massachusetts. Both parties agree to
        submit to the exclusive personal jurisdiction and venue of such courts.
      </p>
      <h3>17. Entire Agreement</h3>
      <p>
        These Terms constitute the entire agreement between you and Fit for
        Everybody regarding your use of the Website, and supersede all prior
        written or oral agreements.
      </p>
      <h3>18. Contact Us</h3>
      <p>
        If you have any questions about the Website, please do not hesitate to
        contact us at{" "}
        <a href="mailto:info@fitforeverybody.com">info@fitforeverybody.com</a>
      </p>
    </div>
  );
};

export default TermsOfUse;
