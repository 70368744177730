/**
 * Calculates average read time of a text body
 * The return value number of minutes (int) it takes to read the text body
 * @param {string} bar - text body
 */
const calculateReadTime = (body) => {
  const AVERAGE_READING_SPEED = 250; // words per minute
  const numberOfWords = body.split(" ").length;
  return Math.round(numberOfWords / AVERAGE_READING_SPEED) || 1;
};

export { calculateReadTime };
