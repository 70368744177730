import { useState, useEffect } from "react";
import "./HeightInput.css";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import TextField from "@mui/material/TextField";

import InputAdornment from "@mui/material/InputAdornment";

const HeightInput = ({
  value,
  setValue,
  disabled = false,
  variant = "standard",
}) => {
  const [isMetric, setIsMetric] = useState(true); //when disabled, show metric just for simpler design

  const [ft, setFt] = useState("");
  const [inch, setInch] = useState("");

  useEffect(() => {
    setValue(ft * 30.48 + inch * 2.54);
  }, [ft, inch, setValue]);

  return (
    <div className="height-input-container">
      {!disabled && (
        <div className="switch-container">
          <FormControlLabel
            control={
              <Switch
                defaultChecked
                checked={isMetric}
                onChange={() => {
                  setIsMetric(!isMetric);
                  setValue("");
                  setFt("");
                  setInch("");
                }}
                color="secondary"
              />
            }
            label="Use Metric"
          />
        </div>
      )}

      {isMetric ? (
        <div className="input-container">
          <TextField
            className="input"
            sx={{ m: 1, width: "90%" }}
            label="Height"
            InputProps={{
              endAdornment: <InputAdornment position="end">cm</InputAdornment>,
            }}
            variant={variant}
            value={value}
            onChange={(event) => setValue(event.target.value)}
            type="number"
            disabled={disabled}
          />
        </div>
      ) : (
        <div className="input-container">
          <TextField
            label="Height"
            sx={{ m: 1, width: "90%" }}
            InputProps={{
              endAdornment: <InputAdornment position="end">ft</InputAdornment>,
            }}
            variant={variant}
            value={ft}
            onChange={(event) => setFt(event.target.value)}
            type="number"
            disabled={disabled}
          />
          <TextField
            sx={{ m: 1, width: "90%" }}
            InputProps={{
              endAdornment: <InputAdornment position="end">in</InputAdornment>,
            }}
            variant={variant}
            value={inch}
            onChange={(event) => setInch(event.target.value)}
            type="number"
            disabled={disabled}
          />
        </div>
      )}
    </div>
  );
};

export default HeightInput;
