import "./ShopperCallToAction.css"

const ShopperCallToAction = () => {

    return (
        <p id="shopper-cta-container">
            <div className="cta-section">
                <img src="./pencil.png" alt="Icon of a pencil and ruler" />
                <p>Learn how to take accurate body measurements</p>
            </div>
            <div className="cta-section">
                <img src="./truck.png" alt="Icon of a truck" />
                <p>Save money on your clothing expenses from returns</p>
            </div>
            <div className="cta-section">
                <img src="./smile.png" alt="Icon of a smiley face" />
                <p>Boost confidence by finding the right clothes</p>
            </div>
        </p>
    );
};

export default ShopperCallToAction;