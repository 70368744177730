import "./PressComponent.css";

const PressComponent = ({ imgUrl, title, link }) => {
  return (
    <div className="press-component-container">
      <div className="image-container">
        <img src={imgUrl} alt={title} className="image" />
      </div>
      <div className="title-container">
        <a href={link} target="_blank" rel="noreferrer">
          <p className="component-title">{title}</p>
        </a>
      </div>
    </div>
  );
};

export default PressComponent;
