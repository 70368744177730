import "./ShopperDesigner.css";
import { Link } from "react-router-dom";
import Newsletter from "../components/Newsletter";
import Button from "@mui/material/Button";
import ShopperQandA from "../components/ShopperQandA";
import ShopperCallToAction from "../components/ShopperCallToAction";

const Shopper = () => {
  return (
    <section>
      <section className="shopper-designer-container">
        <h1>For Shoppers</h1>
        <p>
          Garment fit is complex, and determined by a combination of personal
          taste and preferences, garment measurements and style, and your
          physical body measurements.
        </p>
        <p>
          We teach you how to take your body measurements & give you some
          helpful tips for finding clothing that fits.
        </p>
        <p>Login below to create & save your size profile. </p>
        <ShopperCallToAction />
        <Link to="login">
          <Button variant="outlined" className="log-in-btn">
            Log In
          </Button>
        </Link>
        <ShopperQandA />
        <Link to="login">
          <Button variant="outlined" className="log-in-btn">
            Log In
          </Button>
        </Link>
      </section>
      <Newsletter />
    </section>
  );
};

export default Shopper;
