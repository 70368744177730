import "./ShopperDesigner.css";
import Newsletter from "../components/Newsletter";
import DesignerEmail from "../components/DesignerEmail";
import DesignerCallToAction from "../components/DesignerCallToAction";
import DesignerQandA from "../components/DesignerQandA";

const Designer = () => {
  return (
    <section>
      <section className="shopper-designer-container">
        <h1>For Designers</h1>
        <p>
          Sizing begins with your sampling process.
          <br />
          Too often, production (i.e. sizing) decisions are made off of samples that are incorrect.
          <br />
          <br />
          Our digital tech packing products are easy to use for designers and factories
          <br />
          so samples come in on spec and consistent, no surprises.
        </p>
        <DesignerCallToAction />
        <DesignerEmail />
        <DesignerQandA />
        <DesignerEmail />
      </section>
      <Newsletter />
    </section>
  );
};

export default Designer;
