import "./BlogPost.css";
import { Link } from "react-router-dom";
import { AiOutlineClockCircle } from "react-icons/ai";

// import helper
import { calculateReadTime } from "../utils/helper";

// possibly adding date, author, topic
const BlogPost = ({ id, title, body_string }) => {
  const readTime = calculateReadTime(body_string);

  return (
    <Link className="post-container" to={`/blogpost/${id}`}>
      <span className="title">{title}</span>
      <div className="read-time-container">
        <AiOutlineClockCircle className="read-time-icon" />
        <span className="read-time">{readTime} minute read</span>
      </div>
      <div className="body long-and-truncated">{body_string}</div>
    </Link>
  );
};

export default BlogPost;
