import "./BlogPostDetail.css";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { AiOutlineClockCircle } from "react-icons/ai";

// import helper
import { calculateReadTime } from "../utils/helper";

const BlogPostDetail = () => {
  const { id } = useParams();

  const [title, setTitle] = useState("");
  const [body_html, setBodyHtml] = useState("");
  const [readTime, setReadTime] = useState("");

  useEffect(() => {
    fetch("https://fitforeverybodyserver.com/s3-excel", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ name: "blog-page/blog-directory" }),
    })
      .then((response) => response.json())
      .then((data) => {
        const post = data["excel"].find(
          // params: string & id: number by default --> just to be safe, converting both to Int
          (p) => parseInt(p[0]) === parseInt(id)
        );
        setTitle(post[1]);
        setBodyHtml(post[2]);
        setReadTime(calculateReadTime(post[2]));
      });
  }, [id]);

  return (
    <div className="blog-detail-container">
      <span className="title">{title}</span>
      <div className="read-time-container">
        <AiOutlineClockCircle className="read-time-icon" />
        <span className="read-time">{readTime} minute read</span>
      </div>
      <div
        className="body"
        dangerouslySetInnerHTML={{ __html: body_html }}
      ></div>

      <p className="signature">xxL</p>
    </div>
  );
};

export default BlogPostDetail;
