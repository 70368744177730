import { useState, useContext } from "react";
import "./Signup.css";
import { AccountContext } from "../components/Accounts";
import { Link, useHistory } from "react-router-dom";
import TextField from "@mui/material/TextField";
import LoadingButton from "@mui/lab/LoadingButton";
import Checkbox from "../components/Checkbox";
import HeightInput from "../components/HeightInput";
import { countryList, stateList } from "../components/SelectOptions";
import ErrorMessage from "../components/ErrorMessage";
import Button from "@mui/material/Button";

import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

import { BsCircle, BsCircleFill } from "react-icons/bs";

import { CognitoUserAttribute } from "amazon-cognito-identity-js";
import UserPool from "../components/UserPool";

const PageIndicator = ({ first = true }) => {
  return (
    <div id="page-indicator-container">
      {first ? (
        <>
          <BsCircleFill />
          <BsCircle />
        </>
      ) : (
        <>
          <BsCircle />
          <BsCircleFill />
        </>
      )}
    </div>
  );
};

const Signup = () => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [raceWhite, setRaceWhite] = useState(false);
  const [birthday, setBirthday] = useState("");
  const [raceBlack, setRaceBlack] = useState(false);
  const [raceHispanic, setRaceHispanic] = useState(false);
  const [raceEastAsian, setRaceEastAsian] = useState(false);
  const [raceSouthAsian, setRaceSouthAsian] = useState(false);
  const [raceMiddleEastern, setRaceMiddleEastern] = useState(false);
  const [raceOther, setRaceOther] = useState(false);
  const [raceOtherValue, setRaceOtherValue] = useState("");
  const [height, setHeight] = useState("");
  const [country, setCountry] = useState("");
  const [state, setState] = useState("");

  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const [onFirstPage, setOnFirstPage] = useState(false);

  // for navigating to different page
  const history = useHistory();

  const { authenticate } = useContext(AccountContext);

  const attributes = [
    new CognitoUserAttribute({ Name: "custom:first_name", Value: firstName }),
    new CognitoUserAttribute({ Name: "custom:last_name", Value: lastName }),
    new CognitoUserAttribute({ Name: "custom:birthday", Value: birthday }),
    new CognitoUserAttribute({ Name: "custom:height", Value: String(height) }),
    new CognitoUserAttribute({
      Name: "custom:race_white",
      Value: String(Number(raceWhite)),
    }),
    new CognitoUserAttribute({
      Name: "custom:race_black",
      Value: String(Number(raceBlack)),
    }),
    new CognitoUserAttribute({
      Name: "custom:race_hispanic",
      Value: String(Number(raceHispanic)),
    }),
    new CognitoUserAttribute({
      Name: "custom:race_east_asian",
      Value: String(Number(raceEastAsian)),
    }),
    new CognitoUserAttribute({
      Name: "custom:race_south_asian",
      Value: String(Number(raceSouthAsian)),
    }),
    new CognitoUserAttribute({
      Name: "custom:race_middle_eastern",
      Value: String(Number(raceMiddleEastern)),
    }),
    new CognitoUserAttribute({
      Name: "custom:race_other",
      Value: String(Number(raceOther)),
    }),
    new CognitoUserAttribute({
      Name: "custom:race_other_value",
      Value: raceOtherValue,
    }),
    new CognitoUserAttribute({ Name: "custom:country", Value: country }),
    new CognitoUserAttribute({ Name: "custom:state", Value: state }),
  ];

  const nextPage = () => {
    // if on the second page already, move to the previous (first) page
    if (!onFirstPage) return setOnFirstPage(true);

    if (!firstName) setErrorMessage("First name is required.");
    else if (!lastName) setErrorMessage("Last name is required.");
    else if (!email) setErrorMessage("Email is required.");
    else if (!password) setErrorMessage("Password is required.");
    else if (password.length < 6)
      setErrorMessage("Password must be at least 6 characters.");
    else {
      setOnFirstPage(false);
      setErrorMessage("");
    }
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    setLoading(true);
    UserPool.signUp(email, password, attributes, null, (err, data) => {
      if (err) {
        setErrorMessage(err.message);
        setLoading(false);
      } else {
        authenticate(email, password)
          .then((data) => {
            history.push("/shopper-landing-page");
          })
          .catch((err) => {
            setErrorMessage(err.message);
            setLoading(false);
          });
      }
    });
  };

  return (
    <div className="signup-container">
      <div className="signup-logo-container">
        <img src="/signup-logo.svg" alt="signup-logo" className="signup-logo" />
      </div>
      <div className="form">
        <h1 className="welcome-message">Welcome!</h1>

        {onFirstPage ? (
          <>
            <PageIndicator />
            <TextField
              className="signup-input"
              label="First Name"
              variant="standard"
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
            />
            <TextField
              className="signup-input"
              label="Last Name"
              variant="standard"
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
            />
            <TextField
              className="signup-input"
              label="Email"
              variant="standard"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              type="email"
            />
            <TextField
              className="signup-input"
              label="Password"
              variant="standard"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              type={showPassword ? "text" : "password"}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => setShowPassword(!showPassword)}
                      onMouseDown={() => setShowPassword(!showPassword)}
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <TextField
              className="signup-input"
              label="Birthday"
              variant="standard"
              value={birthday}
              onChange={(e) => setBirthday(e.target.value)}
              type="date"
              InputLabelProps={{ shrink: true }}
            />
            <TextField
              select
              defaultValue={countryList[0]}
              label="Country"
              value={country}
              onChange={(e) => setCountry(e.target.value)}
              SelectProps={{
                native: true,
              }}
              variant="standard"
            >
              {countryList.map((country) => (
                <option key={country} value={country}>
                  {country}
                </option>
              ))}
            </TextField>
            <TextField
              select
              label="State"
              value={state}
              onChange={(e) => setState(e.target.value)}
              SelectProps={{
                native: true,
              }}
              variant="standard"
              disabled={country !== "United States of America"}
            >
              {stateList.map((state) => (
                <option key={state} value={state}>
                  {state}
                </option>
              ))}
            </TextField>
            <ErrorMessage message={errorMessage} />
            <Button
              variant="outlined"
              className="signup-btn"
              onClick={nextPage}
            >
              Next
            </Button>
            <p>
              Already have an account? <Link to="/login">Log in.</Link>
            </p>
          </>
        ) : (
          <>
            <PageIndicator first={false} />
            <Checkbox
              label="Race"
              value={raceOtherValue}
              setValue={setRaceOtherValue}
              checkboxItems={[
                { title: "White", value: raceWhite, setValue: setRaceWhite },
                { title: "Black", value: raceBlack, setValue: setRaceBlack },
                {
                  title: "Hispanic/Latin",
                  value: raceHispanic,
                  setValue: setRaceHispanic,
                },
                {
                  title: "East Asian",
                  value: raceEastAsian,
                  setValue: setRaceEastAsian,
                },
                {
                  title: "South Asian",
                  value: raceSouthAsian,
                  setValue: setRaceSouthAsian,
                },
                {
                  title: "Middle Eastern/ Arab",
                  value: raceMiddleEastern,
                  setValue: setRaceMiddleEastern,
                },
                { title: "Other: ", value: raceOther, setValue: setRaceOther },
              ]}
              tooltipMessage="Why we ask: All demographics we request are to help us understand sizing and body shape. We will not share this information, and all data is anonymized when aggregated and analyzed. "
            />
            <HeightInput label="Height" value={height} setValue={setHeight} />
            <ErrorMessage message={errorMessage} />
            <Button
              variant="outlined"
              className="signup-btn previous"
              onClick={nextPage}
            >
              Previous
            </Button>
            <LoadingButton
              loading={loading}
              variant="outlined"
              className="signup-btn"
              onClick={handleSubmit}
            >
              Sign Up
            </LoadingButton>
          </>
        )}
      </div>
    </div>
  );
};

export default Signup;
