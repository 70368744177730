import { Component } from "react";

class Last extends Component {
  render(props) {
    return (
      <div style={{ textAlign: "center", height: "35vh" }}>
        {this.props.api_error ? (
          <div>
            <h2>Oops, something went wrong.</h2>
            <p>
              Please check your network connection and try again. If this error
              continues, please email info@fitforeverybody.com for assistance.
            </p>
          </div>
        ) : (
          <div>
            <h2>Thank you for contributing your measurements</h2>
          </div>
        )}
        <br />
      </div>
    );
  }
}

export default Last;
