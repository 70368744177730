import "./DesignerEmail.css";
import { useRef, useState } from "react";
import * as dayjs from "dayjs";
import * as utc from "dayjs/plugin/utc";
import * as timezone from "dayjs/plugin/timezone";
import emailjs from "@emailjs/browser";
import { TextField, Button, Grid } from "@mui/material";

const DesignerEmail = () => {
  const [email, setEmail] = useState("");
  const form = useRef();

  const sendEmail = (event) => {
    event.preventDefault();
    dayjs.extend(utc);
    dayjs.extend(timezone);

    const timestamp = dayjs().tz("America/New_York").toString();
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (!re.test(email)) {
      setEmail("Not Valid Email");
      return;
    }

    const templateParams = {
      designer_email: email,
      time_submitted: timestamp,
    };

    emailjs
      .send(
        process.env.REACT_APP_EMAILJS_SERVICE_ID,
        process.env.REACT_APP_EMAILJS_TEMPLATE_ID,
        templateParams,
        process.env.REACT_APP_EMAILJS_PUBLIC_KEY
      )
      .then(
        (result) => {
          console.log(result.text);
          setEmail("Email Saved");
        },
        (error) => {
          console.log(error.text);
        }
      );
  };

  return (
    <p id="designer-email-container">
      <p id="more-info">For more information</p>
      <form ref={form}>
        <TextField
          label="Enter your email"
          id="designer-email-textfield"
          name="designer_email"
          variant="outlined"
          color="secondary"
          type="email"
          onChange={(e) => setEmail(e.target.value)}
          value={email}
        />
        <Button
          color="secondary"
          id="designer-email-btn"
          variant="outlined"
          onClick={sendEmail}
        >
          Submit Email
        </Button>
      </form>
    </p>
  );
};

export default DesignerEmail;
