import React, { useState, useContext } from "react";
import { AccountContext } from "../components/Accounts";
import "./Login.css";
import LoadingButton from "@mui/lab/LoadingButton";
import TextField from "@mui/material/TextField";
import ErrorMessage from "../components/ErrorMessage";
import { Link, useHistory } from "react-router-dom";

import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);

  const [showPassword, setShowPassword] = useState(false);
  // for navigating to different page
  const history = useHistory();

  const { authenticate } = useContext(AccountContext);

  const handleSubmit = (event) => {
    event.preventDefault();
    setLoading(true);

    authenticate(email, password)
      .then((data) => {
        history.push("/shopper-landing-page");
      })
      .catch((err) => {
        if (!email) setErrorMessage("Email is required.");
        else if (!password) setErrorMessage("Password is required.");
        else setErrorMessage(err.message);
        setLoading(false);
      });
  };

  return (
    <div className="login-container">
      <div className="form" onSubmit={handleSubmit}>
        <h1 className="welcome-message">Welcome Back!</h1>
        <img
          src="/login-logo.svg"
          alt="/login-logo"
          className="login-logo mobile"
        />
        <TextField
          className="login-input"
          label="Email"
          variant="standard"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <TextField
          className="login-input"
          label="Password"
          variant="standard"
          type={showPassword ? "text" : "password"}
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={() => setShowPassword(!showPassword)}
                  onMouseDown={() => setShowPassword(!showPassword)}
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <Link to="/forgot-password">Forgot password?</Link>
        <ErrorMessage message={errorMessage} className="login-error-message" />
        <LoadingButton
          loading={loading}
          variant="outlined"
          className="log-in-btn"
          onClick={handleSubmit}
        >
          Log In
        </LoadingButton>
        <p>
          Don't have an account? <Link to="/signup">Sign up.</Link>
        </p>
      </div>
      <div className="login-logo-container">
        <img src="/login-logo.svg" alt="login-logo" className="login-logo" />
      </div>
    </div>
  );
};

export default Login;
