import { Component } from "react";
import "./Instruction-Introduction.css";
import Button from "@mui/material/Button";
import LoadingButton from "@mui/lab/LoadingButton";

class Instruction extends Component {
  continue = (e) => {
    e.preventDefault();
    this.props.nextStep();
  };

  back = (e) => {
    e.preventDefault();
    this.props.prevStep();
  };

  render() {
    return (
      <div className="instruction-introduction-container">
        <h2 className="title">Instruction</h2>
        {this.props.type !== "self" ? (
          <p className="body">
            Ask a friend or family member to help you take your measurements.
            Measurements are most accurate when you wear fitted clothing, like
            leggings and a tank top. Pull back long hair, and take off your
            shoes. We recommend tying a string around your waist (the narrowest
            point of your torso), to make measuring faster and easier.
          </p>
        ) : (
          <p className="body">
            A curated list of measurements for those measuring solo.
            Measurements are most accurate when you wear fitted clothing, like
            leggings and a tank top. Pull back long hair, and take off your
            shoes. We recommend tying a string around your waist (the narrowest
            point of your torso), to make measuring faster and easier. For self
            measuring, we suggest{" "}
            <a
              href="https://www.amazon.com/Measuring-Measure-Button-Retract-Measurement/dp/B081Q5WLFL/ref=sr_1_10?dchild=1&keywords=self%20measuring%20tape&qid=1593693311&sr=8-10"
              target="_blank"
              rel="noreferrer"
            >
              this
            </a>{" "}
            tape measure. Please measure to 0.5 cm and 0.25 inch, please enter
            all measurements as decimal.
          </p>
        )}
        {this.props.type !== "self" ? (
          <p className="body">
            Note: taking measurements is <strong>NOT</strong> a social
            distancing activity. Please measure to 0.5 cm and 0.25 inch, please
            enter all measurements as decimal.
          </p>
        ) : null}
        <div className="btn-container">
          <Button variant="outlined" className="btn" onClick={this.back}>
            Back
          </Button>
          <LoadingButton
            variant="outlined"
            className="btn"
            onClick={this.continue}
            loading={!this.props.excel_retrieved}
          >
            Continue
          </LoadingButton>
        </div>
      </div>
    );
  }
}

export default Instruction;
