import { Component } from "react";
import "./Measurement.css";
import Switch from "../components/Switch";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import Button from "@mui/material/Button";
import { AiOutlinePicture, AiOutlineVideoCamera } from "react-icons/ai";

class Measurement extends Component {
  constructor(props) {
    super(props);
    this.state = { button_disabled: false };
  }
  continue = async (e) => {
    e.preventDefault();

    this.setState({ button_disabled: true });
    if (this.props.number === this.props.length - 1) this.props.postData();
    else {
      this.props.nextStep();
      this.setState({ button_disabled: false });
    }
  };

  back = (e) => {
    e.preventDefault();
    this.props.prevStep();
  };

  render() {
    const {
      showImage,
      showMetric,
      switchMedia,
      switchUnit,
      number,
      data,
      length,
      handleChange,
      values,
    } = this.props;

    const [bodyPart, value, image, video, description, videoStart, videoEnd] =
      data;

    return (
      <div className="measurement-container">
        <h3 className="title">
          {number + 1}/{length}: {bodyPart}
        </h3>
        <div className="inner-container-without-title">
          <div className="media-left-container">
            <div className="switch-container">
              <AiOutlinePicture size={30} className="switch-label" />
              <Switch
                isToggled={!showImage}
                onToggle={() => switchMedia(!showImage)}
                color="pink"
              />
              <AiOutlineVideoCamera size={30} className="switch-label" />
            </div>

            {showImage ? (
              <img className="image" src={image} alt="Measurement" />
            ) : (
              <iframe
                title="Video"
                className="video"
                src={`${video}?start=${videoStart};end=${videoEnd}`}
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            )}
          </div>

          <div className="text-right-container">
            <p className="description">{description}</p>
            {number === 0 ? (
              <div className="switch-container">
                <span className="switch-label">cm</span>
                <Switch
                  isToggled={showMetric}
                  onToggle={() => switchUnit(!showMetric)}
                  color="pink"
                />
                <span className="switch-label">in</span>
              </div>
            ) : null}
            {!showMetric && (
              <span style={{ marginTop: 20, textAlign: "center" }}>
                If you are using the tape measure from MIT Delta V Demo Day on
                September 10th, please complete the surveys using cm, instead of
                inches.
              </span>
            )}
            <TextField
              className="input"
              sx={{ m: 1, width: "300px" }}
              label="measurement"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {showMetric ? "cm" : "in"}
                  </InputAdornment>
                ),
              }}
              variant="outlined"
              value={values.values[value]}
              onChange={handleChange(value)}
              type="number"
            />
            <div className="btn-container">
              <Button
                className="btn"
                variant="outlined"
                onClick={this.back}
                disabled={this.state.button_disabled}
              >
                Back
              </Button>

              <Button
                className="btn"
                variant="outlined"
                onClick={this.continue}
                disabled={this.state.button_disabled}
              >
                Continue
              </Button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Measurement;
