import { useContext, useEffect, useState } from "react";
import { AccountContext } from "../components/Accounts";
import { Link } from "react-router-dom";
import Newsletter from "../components/Newsletter";
import "./Home.css";

const Home = () => {
  const { getSession } = useContext(AccountContext);
  const [loggedIn, setLoggedIn] = useState(false);

  useEffect(() => {
    getSession().then((session) => {
      setLoggedIn(true);
    });
  });

  return (
    <div>
      <div
        className="user-type-container"
      >
          <Link
              to={"/designer"}
              className="user-type"
              id="designer-img"
          >
              <p>I'm a Designer</p>
          </Link>
          <p className="cta-text">
              Fit for Everybody is a web
              <br />
              based product development
              <br />
              platform for apparel designers
              <br />
              and factories to streamline
              <br />
              sampling and sizing.
          </p>
      </div>
        <div
            className="user-type-container"
        >
            <p className="cta-text">
                For shoppers, we
                <br />
                teach you how to take
                <br />
                your body measurements to
                <br/>
                help you shop better and
                <br />
                improve fit in the future.
            </p>
            <Link
                to={loggedIn ? "shopper-landing-page" : "/shopper"}
                className="user-type"
                id="shopper-img"
            >
                <p>I'm a Shopper</p>
            </Link>
        </div>
      <a
        className="UN-logo-container"
        href="https://sdgs.un.org/partnerships/fit-everybody"
        target="_blank"
        rel="noreferrer"
      >
        <img
          src="./un-network-logo.png"
          alt="UN conscious fashion and lifestyle network"
          className="UN-logo"
        />
      </a>
      <Newsletter />
    </div>
  );
};

export default Home;
