const ErrorMessage = ({ message }) => {
  return <p style={errorMessageStyles.message}>{message}</p>;
};

const errorMessageStyles = {
  message: {
    color: "red",
    width: "100%",
  },
};

export default ErrorMessage;
