import "./FileUpload.css";
import { AiOutlineFileAdd, AiOutlineDelete } from "react-icons/ai";
import { useRef } from "react";

const UploadedFile = ({ fileName, setFile, fileInputRef, name }) => {
  return (
    <div id="uploaded-file-container">
      {fileName}{" "}
      <AiOutlineDelete
        size={20}
        id="file-delete-icon"
        onClick={() => {
          setFile();
          // reset the value of file input
          // necessary to allow uploading the same file after deleting
          fileInputRef.current.value = "";
        }}
      />
    </div>
  );
};

const FileUpload = ({ setFile, file, accept, loading, name, title }) => {
  const fileInputRef = useRef();

  return (
    <div id="upload-file-container">
      <div id="file-input">
        <input
          id="default-input"
          onChange={(e) => setFile(e.target.files[0])}
          type="file"
          accept={accept}
          name={name}
          ref={fileInputRef}
        />
        <button id="upload-btn">
          <AiOutlineFileAdd id="file-upload-icon" size={25} />
          Upload
        </button>
      </div>
      {file ? (
        <UploadedFile
          fileName={file.name}
          setFile={setFile}
          fileInputRef={fileInputRef}
        />
      ) : (
        `${title} ${accept.replace("/*", "")}`
      )}
    </div>
  );
};

export default FileUpload;
