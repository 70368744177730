const MeasurementComponent = ({ completed_on, survey_type }) => {
  return (
    <div style={MeasurementComponentStyles.container}>
      <span style={MeasurementComponentStyles.date}>Date: {completed_on}</span>
      <span style={MeasurementComponentStyles.measurement}>
        {survey_type} Measurements
      </span>
    </div>
  );
};

const MeasurementComponentStyles = {
  container: {
    // border: "1px solid black",
    borderRadius: 5,
    paddingLeft: 10,
    display: "flex",
    flexDirection: "column",
    margin: 5,
    backgroundColor: "rgb(248, 231, 237)",
  },
  date: {
    fontWeight: "bold",
    marginTop: 3,
  },
  measurement: {
    marginTop: 2,
  },
};

export default MeasurementComponent;
