import "./MyMeasurements.css";
import { useEffect, useState, useContext } from "react";
import { Link, useHistory } from "react-router-dom";
import { AccountContext } from "../components/Accounts";
import { AiFillCaretDown, AiFillCaretUp } from "react-icons/ai";
import LoadingButton from "@mui/lab/LoadingButton";
import Button from "@mui/material/Button";
import Checkbox from "../components/Checkbox";
import MeasurementComponent from "../components/MeasurementComponent";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import { countryList, stateList } from "../components/SelectOptions";

import { CognitoUserAttribute } from "amazon-cognito-identity-js";

const MyMeasurements = () => {
  const { getSession, loggedIn } = useContext(AccountContext);
  const history = useHistory();

  // boolean for showing information
  const [showDemographics, setShowDemographics] = useState(false);
  const [showMeasurementHistory, setShowMeasurementHistory] = useState(false);
  const [showMyMeasurements, setShowMyMeasurements] = useState(false);

  const [loading, setLoading] = useState(false);

  const [surveys, setSurveys] = useState([]);

  // demographics information
  const [demographicsEditable, setDemographicsEditable] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [birthday, setBirthday] = useState("");
  const [height, setHeight] = useState("");
  const [races, setRaces] = useState("");
  const [raceWhite, setRaceWhite] = useState(false);
  const [raceBlack, setRaceBlack] = useState(false);
  const [raceHispanic, setRaceHispanic] = useState(false);
  const [raceEastAsian, setRaceEastAsian] = useState(false);
  const [raceSouthAsian, setRaceSouthAsian] = useState(false);
  const [raceMiddleEastern, setRaceMiddleEastern] = useState(false);
  const [raceOther, setRaceOther] = useState(false);
  const [raceOtherValue, setRaceOtherValue] = useState("");
  const [country, setCountry] = useState("");
  const [state, setState] = useState("");

  // turning boolean values of race into a string representation of races
  const displayRaces = (data) => {
    let raceValue = "";
    if (data["custom:race_black"] === "1") raceValue += "Black, ";
    if (data["custom:race_hispanic"] === "1") raceValue += "Hispanic/Latin, ";
    if (data["custom:race_east_asian"] === "1") raceValue += "East Asian, ";
    if (data["custom:race_south_asian"] === "1") raceValue += "South Asian, ";
    if (data["custom:race_middle_eastern"] === "1")
      raceValue += "Middle Eastern/Arab, ";
    if (data["custom:race_other"] === "1")
      raceValue += ` ${data["custom:race_other_value"]}, `;

    // removing "," and " " after the race string
    if (raceValue) raceValue = raceValue.substring(0, raceValue.length - 2);

    setRaces(raceValue);
  };

  const update = () => {
    setLoading(true);
    getSession().then(({ user }) => {
      const attributes = [
        new CognitoUserAttribute({
          Name: "email",
          Value: email,
        }),
        new CognitoUserAttribute({
          Name: "custom:first_name",
          Value: firstName,
        }),
        new CognitoUserAttribute({ Name: "custom:last_name", Value: lastName }),
        new CognitoUserAttribute({ Name: "custom:birthday", Value: birthday }),
        new CognitoUserAttribute({
          Name: "custom:height",
          Value: String(height),
        }),
        new CognitoUserAttribute({
          Name: "custom:race_white",
          Value: String(Number(raceWhite)),
        }),
        new CognitoUserAttribute({
          Name: "custom:race_black",
          Value: String(Number(raceBlack)),
        }),
        new CognitoUserAttribute({
          Name: "custom:race_hispanic",
          Value: String(Number(raceHispanic)),
        }),
        new CognitoUserAttribute({
          Name: "custom:race_east_asian",
          Value: String(Number(raceEastAsian)),
        }),
        new CognitoUserAttribute({
          Name: "custom:race_south_asian",
          Value: String(Number(raceSouthAsian)),
        }),
        new CognitoUserAttribute({
          Name: "custom:race_middle_eastern",
          Value: String(Number(raceMiddleEastern)),
        }),
        new CognitoUserAttribute({
          Name: "custom:race_other",
          Value: String(Number(raceOther)),
        }),
        new CognitoUserAttribute({
          Name: "custom:race_other_value",
          Value: raceOtherValue,
        }),
        new CognitoUserAttribute({ Name: "custom:country", Value: country }),
        new CognitoUserAttribute({ Name: "custom:state", Value: state }),
      ];

      user.updateAttributes(attributes, (err, result) => {
        if (err) console.error(err);
        console.log(result);
        setDemographicsEditable(false);
        setLoading(false);
      });
    });
  };

  useEffect(() => {
    if (!loggedIn) return history.push("/login");
    // condition to prevent from fetching while editing demographics information, which keeps going back to the current value
    if (!demographicsEditable)
      getSession().then((data) => {
        setFirstName(data["custom:first_name"]);
        setLastName(data["custom:last_name"]);
        setEmail(data["email"]);
        setBirthday(data["custom:birthday"]);
        setHeight(data["custom:height"]);
        setCountry(data["custom:country"]);
        setState(data["custom:state"]);
        displayRaces(data);
        setRaceWhite(data["custom:race_white"] === "1");
        setRaceBlack(data["custom:race_black"] === "1");
        setRaceHispanic(data["custom:race_hispanic"] === "1");
        setRaceEastAsian(data["custom:race_east_asian"] === "1");
        setRaceSouthAsian(data["custom:race_south_asian"] === "1");
        setRaceMiddleEastern(data["custom:race_middle_eastern"] === "1");
        setRaceOther(data["custom:race_other"] === "1");
        setRaceOtherValue(data["custom:race_other_value"]);

        fetch("https://fitforeverybodyserver.com/get-survey", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            user_id: data["sub"],
          }),
        })
          .then((response) => response.json())
          .then((data) => setSurveys(data.data));
      });
  }, [demographicsEditable, getSession, history, loggedIn]);

  useEffect(() => {
    setState("");
  }, [country]);

  return (
    <div className="my-measurement-container">
      {/* <h1 className="title">{firstName}'s Measurement Profile</h1>
      <h2 onClick={() => setShowMyMeasurements(!showMyMeasurements)}>
        My Measurements <AiFillCaretDown className="icon" />
      </h2> */}
      {showMyMeasurements && <div className="information-container"></div>}
      <h2 onClick={() => setShowMeasurementHistory(!showMeasurementHistory)}>
        Measurement History{" "}
        {showMeasurementHistory ? <AiFillCaretUp /> : <AiFillCaretDown />}
      </h2>
      {showMeasurementHistory && (
        <div className="information-container">
          {surveys.length ? (
            surveys.map((survey, i) => (
              <Link
                to={`/measurement-detail/${survey.survey_type}/${survey.id}`}
                key={i}
              >
                <MeasurementComponent
                  completed_on={new Date(survey.completed_on).toDateString()}
                  survey_type={survey.survey_type}
                />
              </Link>
            ))
          ) : (
            <span className="no-measurement-text">
              No measurement taken yet.
            </span>
          )}
        </div>
      )}
      <h2 onClick={() => setShowDemographics(!showDemographics)}>
        Demographics{" "}
        {showDemographics ? <AiFillCaretUp /> : <AiFillCaretDown />}
      </h2>
      {showDemographics && (
        <div className="information-container">
          <TextField
            label="First Name"
            variant="outlined"
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
            disabled={!demographicsEditable}
          />
          <TextField
            label="Last Name"
            variant="outlined"
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
            disabled={!demographicsEditable}
          />
          <TextField
            label="Email"
            variant="outlined"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            disabled={!demographicsEditable}
          />
          <TextField
            label="Birthday"
            variant="outlined"
            type="date"
            value={birthday}
            onChange={(e) => setBirthday(e.target.value)}
            disabled={!demographicsEditable}
            InputLabelProps={{ shrink: true }}
          />
          <TextField
            select
            defaultValue={countryList[0]}
            label="Country"
            value={country}
            onChange={(e) => setCountry(e.target.value)}
            SelectProps={{
              native: true,
            }}
            variant="outlined"
            disabled={!demographicsEditable}
            InputLabelProps={{ shrink: true }}
          >
            {countryList.map((country) => (
              <option key={country} value={country}>
                {country}
              </option>
            ))}
          </TextField>

          {country === "United States of America" && (
            <TextField
              select
              label="State"
              value={state}
              onChange={(e) => setState(e.target.value)}
              SelectProps={{
                native: true,
              }}
              variant="outlined"
              disabled={!demographicsEditable}
              InputLabelProps={{ shrink: true }}
            >
              {stateList.map((state) => (
                <option key={state} value={state}>
                  {state}
                </option>
              ))}
            </TextField>
          )}
          <TextField
            label="Height"
            InputProps={{
              endAdornment: <InputAdornment position="end">cm</InputAdornment>,
            }}
            variant="outlined"
            value={height}
            onChange={(event) => setHeight(event.target.value)}
            type="number"
            disabled={!demographicsEditable}
          />
          {!demographicsEditable ? (
            <TextField
              label="Race"
              variant="outlined"
              value={races}
              disabled={true}
            />
          ) : (
            <Checkbox
              label="Race"
              value={raceOtherValue}
              setValue={setRaceOtherValue}
              checkboxItems={[
                {
                  title: "White",
                  value: raceWhite,
                  setValue: setRaceWhite,
                },
                {
                  title: "Black",
                  value: raceBlack,
                  setValue: setRaceBlack,
                },
                {
                  title: "Hispanic/Latin",
                  value: raceHispanic,
                  setValue: setRaceHispanic,
                },
                {
                  title: "East Asian",
                  value: raceEastAsian,
                  setValue: setRaceEastAsian,
                },
                {
                  title: "South Asian",
                  value: raceSouthAsian,
                  setValue: setRaceSouthAsian,
                },
                {
                  title: "Middle Eastern/ Arab",
                  value: raceMiddleEastern,
                  setValue: setRaceMiddleEastern,
                },
                {
                  title: "Other: ",
                  value: raceOther,
                  setValue: setRaceOther,
                },
              ]}
              tooltipMessage="Why we ask: All demographics we request are to help us understand sizing and body shape. We will not share this information, and all data is anonymized when aggregated and analyzed. "
            />
          )}

          {demographicsEditable ? (
            <>
              <Button
                variant="outlined"
                className="demographics-btn"
                onClick={() => setDemographicsEditable(false)}
              >
                Cancel
              </Button>
              <LoadingButton
                loading={loading}
                variant="outlined"
                className="demographics-btn update"
                onClick={update}
              >
                Update
              </LoadingButton>
            </>
          ) : (
            <Button
              variant="outlined"
              className="demographics-btn"
              onClick={() => setDemographicsEditable(true)}
            >
              Edit
            </Button>
          )}
        </div>
      )}
    </div>
  );
};

export default MyMeasurements;
