import PressComponent from "../components/PressComponent";
import { useEffect, useState } from "react";
import "./Press.css";
import ReactLoading from "react-loading";

const Press = () => {
  const [list, setList] = useState([]);

  useEffect(() => {
    fetch("https://fitforeverybodyserver.com/s3-excel", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ name: "press-page/press-directory" }),
    })
      .then((response) => response.json())
      .then((data) => setList(data["excel"]));
  });

  return (
    <div className="press-container">
      <h1 className="title">Press Coverage</h1>
      {list.length ? (
        list.map((press, i) => (
          <PressComponent
            key={i}
            imgUrl={press[0]}
            title={press[1]}
            link={press[2]}
          />
        ))
      ) : (
        <div className="spinner-container">
          <ReactLoading type="spinningBubbles" color="pink" width={100} />
        </div>
      )}
    </div>
  );
};

export default Press;
