import { Component } from "react";
import Introduction from "./Introduction";
import Instruction from "./Instruction";
import Last from "./Last";
import Measurement from "./Measurement";
import BodyImage from "./BodyImage";

import { AccountContext } from "../components/Accounts";

class Measurements extends Component {
  static contextType = AccountContext;

  state = {
    showImage: true,
    showMetric: true,
    step: -2,
    api_error: false,
    data: [],
    user_id: "",
    email: "",
    excel_retrieved: false,
    // measurement values e.g. values: {bust: '1', waist: '2'}
    values: {},
  };

  componentDidMount() {
    const { getSession, loggedIn } = this.context;

    if (!loggedIn) return this.props.history.push("/login");

    getSession().then((data) => {
      this.setState({ user_id: data["sub"], email: data["email"] });
    });

    fetch("https://fitforeverybodyserver.com/s3-excel", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        name: `measurements/${this.props.match.params.type}-measurement-directory`,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        this.setState({ data: data["excel"] });
        data["excel"].map((row) =>
          this.setState({ values: { ...this.state.values, [row[1]]: "" } })
        );
      })
      .then(() => {
        this.setState({ excel_retrieved: true });
      });
  }

  // Proceed to next step
  nextStep = () => {
    const { step } = this.state;
    this.setState({
      step: step + 1,
    });
  };

  // Go back to prev step
  prevStep = () => {
    const { step } = this.state;
    this.setState({
      step: step - 1,
    });
  };

  // Handle fields change
  handleChange = (input) => (e) => {
    this.setState({
      values: { ...this.state.values, [input]: e.target.value },
    });
  };

  // post data to the database server
  postData = async () => {
    let { showMetric, values, user_id, email } = this.state;
    // changing measurement unit from in to cm

    fetch("https://fitforeverybodyserver.com/survey", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        survey_type: this.props.match.params.type,
        completed_on: new Date(),
        user_id,
        email,
        showMetric,
        ...values,
      }),
    })
      .then((res) => {
        if (!res.ok) throw Error(res.statusText);
        return res.json();
      })
      .then((data) => this.nextStep())
      .catch((error) => this.setState({ api_error: true }, this.nextStep));
  };

  switchMedia = (bool) => {
    this.setState({
      showImage: bool,
    });
  };
  switchUnit = (bool) => {
    this.setState({
      showMetric: bool,
    });
  };

  render() {
    const {
      showImage,
      showMetric,
      step,
      api_error,
      data,
      values,
      excel_retrieved,
    } = this.state;

    switch (step) {
      case -3:
        return (
          <Introduction
            nextStep={this.nextStep}
            type={this.props.match.params.type}
          />
        );
      case -2:
        return (
          <Instruction
            nextStep={this.nextStep}
            prevStep={this.prevStep}
            type={this.props.match.params.type}
            excel_retrieved={excel_retrieved}
          />
        );
      case -1:
        return <BodyImage prevStep={this.prevStep} nextStep={this.nextStep} />;
      case data.length:
        return (
          <Last api_error={api_error} type={this.props.match.params.type} />
        );
      default:
        return (
          <Measurement
            showImage={showImage}
            showMetric={showMetric}
            switchMedia={this.switchMedia}
            switchUnit={this.switchUnit}
            number={step}
            data={data[step]}
            nextStep={this.nextStep}
            prevStep={this.prevStep}
            handleChange={this.handleChange}
            postData={this.postData}
            length={data.length}
            values={{ values }}
          />
        );
    }
  }
}

export default Measurements;
