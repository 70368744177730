import "./Contact.css";

const Contact = () => {
  return (
    <div className="contact-container">
      <div className="contact-left">
        <img src="./laura-headshot.png" alt="laura-headshot" />
      </div>
      <div className="contact-right">
        <p>
          Laura Zwanziger is the CEO and founder of Fit for Everybody.
        </p>
        <p>
          She previously worked in the design department at Oscar de la Renta.
          She holds an MBA from MIT Sloan and a BS in Apparel Design from
          Cornell University, where she began the foundation work for Fit for
          Everybody.
        </p>
        <p>
          Contact us at:{" "}
          <a href="mailto:info@fitforeverybody.com">
            <u>info@fitforeverybody.com</u>
          </a>
        </p>
      </div>
    </div>
  );
};

export default Contact;
