import "./ShopperLandingPage.css";
import { useContext, useEffect, useState } from "react";
import { AccountContext } from "../components/Accounts";
import { Link, useHistory } from "react-router-dom";
import ReactLoading from "react-loading";

const ShopperLandingPage = () => {
  const history = useHistory();
  const { getSession, loggedIn } = useContext(AccountContext);
  const [firstName, setFirstName] = useState("");

  useEffect(() => {
    // only logged in users can access this page
    if (!loggedIn) return history.push("/login");

    getSession().then((data) => {
      setFirstName(data["custom:first_name"]);
    });
  });

  return (
    <div>
      {firstName ? (
        <div className="shopper-landing-page-container">
          <h1 className="welcome-message">
            Welcome{" "}
            <span className={firstName.length < 20 ? "name" : "name long"}>
              {firstName}!
            </span>
          </h1>
          <div className="link dropdown-button">
            <h2>how to measure</h2>
            <div className="dropdown">
              <Link to="/measurements/10">10 Measurements</Link>
              <Link to="/measurements/20">20 Measurements</Link>
              <Link to="/measurements/all">All Measurements</Link>
              <Link to="/measurements/self">Self Measurements</Link>
            </div>
          </div>
          <Link to="/my-measurements" className="link">
            <h2>my measurements</h2>
          </Link>
          <Link to="fit-tips" className="link">
            <h2>fit tips</h2>
          </Link>
          <p className="tape-measure-statement">
            No tape measure? No problem! Sign up{" "}
            <a
              href="https://docs.google.com/forms/d/e/1FAIpQLScNU93iUzwfibg4xTTkRMJzJcvGVntLuo_1XExUXBu9m518cA/viewform"
              target="_blank"
              rel="noreferrer"
              className="tapemeasure-link"
            >
              here
            </a>
            , and we will send you a tape measure ASAP.
          </p>
        </div>
      ) : (
        <div className="spinner-container">
          <ReactLoading type="spinningBubbles" color="pink" width={100} />
        </div>
      )}
    </div>
  );
};

export default ShopperLandingPage;
