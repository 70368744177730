import "./Footer.css";
import { Link } from "react-router-dom";
import {
  AiOutlineLinkedin,
  AiOutlineInstagram,
  AiOutlineFacebook,
  AiOutlineTwitter,
} from "react-icons/ai";

const Footer = () => {
  return (
    <div className="footer-container">
      <p className="footer-note">We respect your privacy.</p>
      <div className="footer-icons">
        <a
          href="https://www.linkedin.com/company/fit-for-everybody/about/"
          target="_blank"
          rel="noreferrer"
        >
          <AiOutlineLinkedin size={30} />
        </a>
        <a
          href="https://www.instagram.com/_fit_for_everybody_/channel/"
          target="_blank"
          rel="noreferrer"
        >
          <AiOutlineInstagram size={30} />
        </a>
        <a
          href="https://www.facebook.com/fitforeverybody2"
          target="_blank"
          rel="noreferrer"
        >
          <AiOutlineFacebook size={30} />
        </a>
        <a
          href="https://twitter.com/FitEverybody"
          target="_blank"
          rel="noreferrer"
        >
          <AiOutlineTwitter size={30} />
        </a>
      </div>

      <div className="footer-legal">
        <span>© {new Date().getFullYear()} Fit for Everybody® Inc.</span>
        <span className="bar">|</span>
        <div className="documents">
          <Link to="/privacy-policy">Privacy Policy</Link>
          <Link to="/terms-of-use">Terms of Use</Link>
        </div>
      </div>
    </div>
  );
};

export default Footer;
