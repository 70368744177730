import "./ShopperQandA.css"

const ShopperQandA = () => {
    return (
        <section id="shopper-qa-container">
            <p>
                <h2>What is Fit for Everybody?</h2>
                Fit for Everybody is a web-based tech pack platform aimed to improve fit and sizing of womenswear from the design stage.
            </p>
            <p>
                <h2>What is the goal of this platform?</h2>
                Our goal is to make clothing design more accessible and less wasteful by including more people in the process of developing the parameters for clothing tag sizes. We want to create an inclusive sizing system that considers a variety of measurements from a variety of people, not just the traditional bust, waist, and hip circumference, but also shoulder width, bicep, and sleeve length, among others.
            </p>
            <p>
                <h2>How will this platform help me find clothes that fit?</h2>
                By teaching you how to take accurate measurements of your body, you will be able to use our platform to identify clothing that may fit you before purchasing. This can save you time and money, as well as reduce the frustration that comes with ill-fitting clothes.
            </p>
            <p>
                <h2>Who is eligible to participate?</h2>
                We welcome all female-identifying volunteers to join our community! We believe that everyone's unique body shape and size is important in creating an inclusive sizing system. We encourage women of all shapes, sizes, ethnicities, nationalities, and ages over 18 to participate in our project.
            </p>
            <p>
                <h2>What will we do with your data?</h2>
                We take data privacy very seriously and want to assure you that any personal data collected from you will be used solely for the purpose of creating an inclusive sizing system, anonymously, and in aggregate. Your measurements will only be used to improve the accuracy of our grade rules and to create more size options for all body types. We will not share your personal information with any third parties without your explicit consent.
            </p>
            <p>
                <h2>Do I need a partner to take accurate measurements?</h2>
                While measurements are most accurate when taken by a partner, we understand that not everyone has someone available to help them. Therefore, we have created a list of measurements that can be taken solo. However, we recommend asking a friend or family member to assist you with measuring whenever possible to ensure the most accurate results.
            </p>
        </section>
    );
};

export default ShopperQandA;