import "./Checkbox.css";
import { AiOutlineInfoCircle } from "react-icons/ai";
import ReactTooltip from "react-tooltip";

const Checkbox = ({
  label,
  value,
  setValue,
  checkboxItems = [],
  tooltipMessage = "",
  style,
}) => {
  return (
    <div className="checkbox-container" style={style}>
      <div className="checkbox-label-container">
        <span className="label">{label}</span>
        {tooltipMessage ? (
          <AiOutlineInfoCircle
            className="label-icon"
            size={20}
            data-tip={tooltipMessage}
          />
        ) : null}
        <ReactTooltip />
      </div>
      <div className="checkbox-options-container">
        {checkboxItems.map((item) => (
          <div className="checkbox-option">
            <input
              className="checkbox"
              type="checkbox"
              name="Other"
              onChange={() => item.setValue(!item.value)}
              checked={item.value}
            />
            <label>{item.title}</label>
            {item.title.includes("Other") && (
              <input
                className="other-input"
                value={value}
                onChange={(event) => setValue(event.target.value)}
              />
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Checkbox;
