import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import ReactGA from 'react-ga4';
import { MessengerChat } from "react-messenger-chat-plugin";
import { Account } from "./components/Accounts";
import ScrollToTop from "./components/ScrollToTop";
import Navbar from "./components/Navbar/Navbar";
import Footer from "./components/Footer";
import Home from "./pages/Home";
import Shopper from "./pages/Shopper";
import ShopperLandingPage from "./pages/ShopperLandingPage";
import Login from "./pages/Login";
import ForgotPassword from "./pages/ForgotPassword";
import Signup from "./pages/Signup";
import Designer from "./pages/Designer";
import FitTips from "./pages/FitTips";
import Contact from "./pages/Contact";
import Blog from "./pages/Blog";
import BlogPostDetail from "./pages/BlogPostDetail";
import About from "./pages/About";
import Press from "./pages/Press";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import TermsOfUse from "./pages/TermsOfUse";
import Measurements from "./pages/Measurements";
import MyMeasurements from "./pages/MyMeasurements";
import MeasurementDetail from "./pages/MeasurementDetail";

function App() {
  ReactGA.initialize('G-1MQ9K6DKDV');
  ReactGA.send({
    hitType: "pageView",
    page: "/",
    title: "Home"
  })
  return (
    <Router>
      <ScrollToTop />
      <section className="App">
        <Account>
          <Navbar />
          <Switch>
            <Route exact path="/" component={Home}></Route>
            <Route path="/shopper" component={Shopper}></Route>
            <Route
              path="/shopper-landing-page"
              component={ShopperLandingPage}
            ></Route>
            <Route path="/login" component={Login}></Route>
            <Route path="/forgot-password" component={ForgotPassword}></Route>
            <Route path="/signup" component={Signup}></Route>
            <Route path="/designer" component={Designer}></Route>
            <Route path="/about" component={About}></Route>
            <Route path="/press" component={Press}></Route>
            <Route path="/contact" component={Contact}></Route>
            <Route path="/blog" component={Blog}></Route>
            <Route path="/blogpost/:id" component={BlogPostDetail}></Route>
            <Route path="/privacy-policy" component={PrivacyPolicy}></Route>
            <Route path="/terms-of-use" component={TermsOfUse}></Route>
            <Route path="/fit-tips" component={FitTips}></Route>
            <Route path="/measurements/:type" component={Measurements}></Route>
            <Route path="/my-measurements" component={MyMeasurements}></Route>
            <Route
              path="/measurement-detail/:survey_type/:id"
              component={MeasurementDetail}
            ></Route>
          </Switch>
          <MessengerChat
            pageId={process.env.REACT_APP_FB_PAGE_ID}
            themeColor={"#BEBEBE"}
            bottomSpacing={30}
          />
        </Account>
        <Footer />
      </section>
    </Router>
  );
}

export default App;
